import React, { useMemo, useState } from 'react';

import { useGetLimitedBusinesses } from '@src/hooks/queries/businesses';
import { useLDUserFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { useGetStandardCategoriesQuery, useGetTemplatesQuery } from '@src/hooks/queries/multi_entity_reports';
import { useGetMultiBusinessReports } from '@src/hooks/queries/report_service/multi_business_report';
import { IGetBusinessesParams } from '@src/requests/businesses';
import { IGetStandardCategoriesResponse } from '@src/requests/multi_entity_reports';
import { getReportBTFEndPoint } from '@src/utils/config';

import { ExportReportBanner } from '@src/components/common_v2/banner';

import BlankPage from './blank_page';
import Header from './header';
import PortfolioList from './portfolio_list';
import PortfolioListHeader from './portfolio_list_header';

const PortfolioListPage = () => {
  const endPoint =
    getReportBTFEndPoint(window.Docyt.Common.Constants.BTF_SERVICE_API_LIST_KEYS.PORTFOLIO_INDEX);

  const [action, setAction] = useState<string>('');
  const [showExportReportBanner, setShowExportReportBanner] = useState<boolean>(false);

  const standardCategoriesQuery = useGetStandardCategoriesQuery();
  const standardCategoryIds = useMemo(() => (
    standardCategoriesQuery?.data?.map((item: IGetStandardCategoriesResponse) => item.id)
  ), [standardCategoriesQuery.data]);

  const selectedPortfolio = JSON.parse(localStorage.getItem('selectedPortfolio') || '{}');
  const portfolioId = selectedPortfolio?.id;
  const portfolioIndustryId = selectedPortfolio?.standardCategoryId;

  const { data: isPortfolioFeatureEnabled } = useLDUserFeatureQuery(
    window.Docyt.currentAdvisor.get('email'),
    window.Docyt.Common.Constants.ENABLE_CREATE_PORTFOLIO_OPTION,
  );

  const templateAPIParams = isPortfolioFeatureEnabled ? [Number(portfolioIndustryId)] : standardCategoryIds as number[];

  const templatesQuery = useGetTemplatesQuery({ 'standard_category_ids[]': templateAPIParams });
  const templates = useMemo(() => templatesQuery.data || [], [templatesQuery.data]);

  const params: IGetBusinessesParams = {
    includeSystemBusinesses: true,
    filter:                  {
      role: 'Admin and Account Manager',
    },
  };
  const businessesQuery = useGetLimitedBusinesses(params);
  const businesses = useMemo(
    () => businessesQuery.data?.collection || [],
    [businessesQuery.data?.collection],
  );

  const reqParam = isPortfolioFeatureEnabled && portfolioId ? { portfolioId } : undefined;
  const multiBusinessReportsQuery = useGetMultiBusinessReports(endPoint, reqParam);
  const multiBusinessReports = useMemo(
    () => multiBusinessReportsQuery.data || [],
    [multiBusinessReportsQuery.data],
  );

  return (
    <>
      <Header
        action={ action }
        businesses={ businesses }
        multiBusinessReports={ multiBusinessReports }
        setAction={ setAction }
        templates={ templates }
        onCreateExportData={ setShowExportReportBanner }
      />
      { showExportReportBanner && (<ExportReportBanner className="m-t-5" />) }

      { multiBusinessReports.length !== 0 ? (
        <>
          <PortfolioListHeader />
          <PortfolioList
            action={ action }
            businesses={ businesses }
            multiBusinessReports={ multiBusinessReports }
            setAction={ setAction }
          />
        </>
      ) : (
        <BlankPage />
      ) }
    </>
  );
};

export default React.memo(PortfolioListPage);
